import { css } from '@emotion/react'
import { type PolicyGuarantee } from '@orus.eu/dimensions'
import { Avatar, colorTokens, InfoDialog, Markdown, useTranslate } from '@orus.eu/pharaoh'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { Fragment, memo, useCallback, useMemo, useState } from 'react'
import { useNavigateBack } from '../../../lib/hooks/use-navigate-back'
import { useLargeScreen } from '../../../lib/use-large-screen'
import { useWindowLocationHash } from '../../../lib/use-window-location-hash'
import { guaranteeImages } from './guarantee-images'

type GuaranteeListProps = {
  guarantees: PolicyGuarantee[]
}

export const GuaranteeList = memo<GuaranteeListProps>(function GuaranteeList(props) {
  const { guarantees } = props

  const [selectedGuarantee, setSelectedGuarantee] = useState<PolicyGuarantee | undefined>(undefined)

  const navigateBack = useNavigateBack()

  const onClick = useCallback((guarantee: PolicyGuarantee) => {
    setSelectedGuarantee(guarantee)
    window.location.hash = `guarantee-${guarantee.type}`
  }, [])

  return (
    <>
      <RowContainer variant="simple" size="large">
        {guarantees.map((guarantee) => (
          <Row
            key={guarantee.type}
            onClick={() => onClick(guarantee)}
            title={guarantee.name}
            leftAvatar={<Avatar variant="contained" src={guaranteeImages[guarantee.type]} size="50" />}
            rightAvatar={<Avatar icon="angle-right-solid" size="30" />}
          />
        ))}
      </RowContainer>

      {selectedGuarantee ? <GuaranteeModal guarantee={selectedGuarantee} onClose={navigateBack} /> : <></>}
    </>
  )
})

type GuaranteeModalProps = {
  guarantee: PolicyGuarantee
  onClose: () => void
}

export const GuaranteeModal = memo<GuaranteeModalProps>(function GuaranteeModal(props) {
  const translate = useTranslate()
  const { guarantee, onClose } = props

  const isLargeScreen = useLargeScreen()

  const hash = useWindowLocationHash()

  const showModal = hash === `guarantee-${guarantee.type}`

  const guaranteeInfoList = useMemo(() => {
    const guaranteeInfos = [
      ...guarantee.guaranteeLimits.map((limit) => ({
        title: 'name' in limit ? limit.name : translate(limit.localizableName),
        content: (
          <>
            <span
              css={css`
                color: ${colorTokens['color-text-base-basic']};
              `}
            >
              {limit.limit ?? translate('limit_of_indemnity_none')}
            </span>

            {` ${limit.description ?? ''}`}
          </>
        ),
      })),
    ]
    if (guarantee.deductibles) {
      guaranteeInfos.push({
        title: translate('deductible'),
        content: (
          <>
            {guarantee.deductibles?.descriptions.map((desc, key, array) => (
              <Fragment key={key}>
                {desc.deductible ? (
                  desc.deductible
                ) : (
                  <span
                    css={css`
                      color: ${colorTokens['color-text-base-basic']};
                    `}
                  >
                    {translate('deductible_none')}
                  </span>
                )}

                {desc.description ? ` ${desc.description}` : undefined}
                {key !== array.length - 1 ? <br /> : <></>}
              </Fragment>
            ))}
          </>
        ),
      })
    }
    return guaranteeInfos
  }, [guarantee, translate])

  return showModal ? (
    <InfoDialog
      variant={isLargeScreen ? 'desktop' : 'mobile'}
      title={guarantee.name}
      avatar={guaranteeImages[guarantee.type]}
      useIllustrations={false}
      sections={[
        {
          name: '',
          type: 'info' as const,
          description: <Markdown markdown={guarantee.fullText} />,
          info: guaranteeInfoList,
          example: <Markdown markdown={guarantee.example} />,
        },
      ]}
      onClose={onClose}
    />
  ) : (
    <></>
  )
})
