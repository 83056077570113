import type { PolicyOptionalGuaranteesType } from '@orus.eu/dimensions'
import type { IllustrationName } from '@orus.eu/pharaoh'
import assistance from './assistance.png'
import attentat from './attentat.png'
import blueHeart from './blue-heart.png'
import brisDeGlace from './bris_de_glace.png'
import catastropheNat from './catastrophe_naturelle.png'
import degatsEaux from './degats_eaux.png'
import dollarBag from './dollar_bag.png'
import euroBankNote from './euro-banknote.png'
import evenementClimatique from './evenement_climatique.png'
import { guaranteeImages } from './guarantee-images'
import hourglass from './hourglass.png'
import house from './house.png'
import incendie from './incendie.png'
import laptop from './laptop.png'
import lawBook2 from './law-book-2.png'
import lawBook3 from './law-book-3.png'
import lawBook from './law-book.png'
import ledger from './ledger.png'
import lockedKey from './locked_key.png'
import dollarFlying from './perte_exploitation.png'
import womanBehindPc from './piratage_site.png'
import recoursJuridiques from './recours_juridiques.png'
import shield from './shield.png'
import truck from './truck.png'
import vandalisme from './vandalisme.png'
import vol from './vol.png'
import womanSwimming from './woman-swimming.png'
import world from './world.png'

export const optionalGuaranteeImages: {
  [key in PolicyOptionalGuaranteesType]: { avatar: string; illustration: IllustrationName }
} = {
  rce: { avatar: lawBook, illustration: 'rce_small' },
  world: { avatar: world, illustration: 'world_small' },
  optionShatteredGlass: { avatar: brisDeGlace, illustration: 'broken_small' },
  optionMarketValueLoss: { avatar: dollarBag, illustration: 'prevoyance_small' },
  optionOperationalLoss: { avatar: dollarFlying, illustration: 'pe_small' },
  optionLegalProtection: { avatar: recoursJuridiques, illustration: 'pj_small' },
  PE: { avatar: dollarFlying, illustration: 'pe_small' },
  PJ: { avatar: recoursJuridiques, illustration: 'pj_small' },
  RCAL: { avatar: lawBook2, illustration: 'rcal_small' },
  RCE: { avatar: lawBook, illustration: 'rce_small' },
  ASSISTANCE: { avatar: assistance, illustration: 'premium_big' },
  FREEDOM_ADVANTAGE: { avatar: '', illustration: 'chainlink_big' },
  CYBER: { avatar: '', illustration: 'cyber_small' },
  MONDE: { avatar: '', illustration: 'world_small' },
  TDMI: { avatar: '', illustration: 'tdmi_small' },
  BDG: { avatar: brisDeGlace, illustration: 'broken_small' },
  BOOST: { avatar: blueHeart, illustration: 'podium_big' },
  PVV: { avatar: euroBankNote, illustration: 'im_small' },
  RCT: { avatar: ledger, illustration: 'rct_small' },
  cyber: { avatar: womanBehindPc, illustration: 'cyber_small' },
  cyberDommagesPe: { avatar: shield, illustration: 'cyber_small' },
  cyberDefaillanceFournisseur: { avatar: truck, illustration: 'cyber_small' },
  cyberDommagesTiers: { avatar: lockedKey, illustration: 'cyber_small' },
  cyberFraudePiratage: { avatar: dollarFlying, illustration: 'cyber_small' },
  tdmi: { avatar: laptop, illustration: 'tdmi_small' },
  mrpSmallOffice: { avatar: house, illustration: 'office_small' },
  mrpSmallOfficeIncendies: { avatar: incendie, illustration: 'shield_red_small' },
  mrpSmallOfficeDegatsDesEaux: { avatar: degatsEaux, illustration: 'umbrella_big' },
  mrpSmallOfficeVol: { avatar: vol, illustration: 'im_small' },
  mrpSmallOfficeVandalisme: { avatar: vandalisme, illustration: 'login_big' },
  mrpSmallOfficeEvenementsClimatiques: {
    avatar: evenementClimatique,
    illustration: 'shield_crystal_small',
  },
  mrpSmallOfficeBrisDeGlace: { avatar: brisDeGlace, illustration: 'broken_small' },
  mrpSmallOfficeCatastrophesNaturelles: {
    avatar: catastropheNat,
    illustration: 'map_big',
  },
  mrpSmallOfficeAttentats: { avatar: attentat, illustration: 'chrono_dark_big' },
  mrpSmallOfficeOccupant: { avatar: lawBook3, illustration: 'lodger_small' },
  mrpSmallOfficeAssistance: { avatar: assistance, illustration: 'premium_big' },
  HISTORY_TAKEOVER: { avatar: hourglass, illustration: 'past_small' },
  SWIMMING_POOL: { avatar: womanSwimming, illustration: 'pool_small' },
  esRcphPostDeliveryCivilLiability1: {
    avatar: guaranteeImages.esRcphPostDeliveryCivilLiability1,
    illustration: 'claim_small',
  },
  esRcphEmployerCivilLiability1: {
    avatar: guaranteeImages.esRcphEmployerCivilLiability1,
    illustration: 'guest_big',
  },
  esRcphOperationalCivilLiability1: {
    avatar: guaranteeImages.esRcphOperationalCivilLiability1,
    illustration: 'claim_small',
  },
  esRcphWorldwideCoverage: {
    avatar: guaranteeImages.esRcphWorldwideCoverage,
    illustration: 'world_small',
  },
  esRcphOperationalCivilLiability2: {
    avatar: guaranteeImages.esRcphOperationalCivilLiability2,
    illustration: 'rce_small',
  },
  esRcphEmployerCivilLiability2: {
    avatar: guaranteeImages.esRcphEmployerCivilLiability2,
    illustration: 'guest_big',
  },
  esRcphPostDeliveryCivilLiability2: {
    avatar: guaranteeImages.esRcphPostDeliveryCivilLiability2,
    illustration: 'claim_small',
  },
  esRcphCrossLiability: {
    avatar: guaranteeImages.esRcphCrossLiability,
    illustration: 'rce_small',
  },
  esRcphPureFinancialLossLiability: {
    avatar: guaranteeImages.esRcphPureFinancialLossLiability,
    illustration: 'chainlink_big',
  },
  esRcphOptionGl: { avatar: guaranteeImages.esRcphPostDeliveryCivilLiability1, illustration: 'rce_small' },
  CROSS_LIABILITY: { avatar: guaranteeImages.esRcphCrossLiability, illustration: 'rcp_small' },
  US_CANADA: { avatar: guaranteeImages.esRcphPureFinancialLossLiability, illustration: 'world_small' },
  GL: { avatar: guaranteeImages.esRcphOperationalCivilLiability1, illustration: 'world_small' },
  PURE_FINANCIAL_LOSS: {
    avatar: guaranteeImages.esRcphPureFinancialLossLiability,
    illustration: 'pe_small',
  },
  OPTION_DIRIGEANT: { avatar: '', illustration: 'manager_small' },
}
