import type { PolicyDeductible, PolicyGuarantee, PolicyOffer } from '@orus.eu/dimensions'
import type { GuaranteesSection } from '@orus.eu/pharaoh/src/legacy/features/quote-v3/guarantees-table'
import { guaranteeImages } from '../../organisms/guarantee/guarantee-images'

export function computeGuaranteesSection(guarantees: PolicyGuarantee[], offers: PolicyOffer[]): GuaranteesSection[] {
  const sections: GuaranteesSection[] = []

  offers.forEach((offer) => {
    const guaranteesForSection = guarantees
      .filter((g) => offer.type === g.offer.type && g.offer.name === offer.name)
      .sort((first, second) => first.priority.localeCompare(second.priority))
    if (guaranteesForSection.length > 0) {
      sections.push({
        id: offer.type,
        name: offer.name,
        guarantees: guaranteesForSection.map((guarantee) => {
          return {
            avatar: guaranteeImages[guarantee.type],
            type: guarantee.type,
            name: guarantee.name,
            shortText: guarantee.shortText,
            deductible: guarantee.deductibles?.descriptions[0]?.deductible,
            ceiling: {
              amount: guarantee.guaranteeLimits[0].limit ?? 'Aucun',
              locked: guarantee.guaranteeLimits[0].isGuaranteeFixed,
            },
          }
        }),
      })
    }
  })

  return sections
}

export function computeDeductibleToDisplay(deductible: PolicyDeductible): string | undefined {
  return deductible.descriptions[0]?.deductible
}
